<template>
  <div class="Insurance">
    <div :class="{ paddingTopHeader: !isSource }">
      <img src="../assets/images/insuranceBanner.png" alt="" />
    </div>
    <div class="item1 paddingTop4 paddingBot4">
      <div class="autowidth">
        <div class="publicTit mobile-publicTit">
          <div class="font30 tit color444 fontBold">我们的医保服务</div>
          <span class="hLine bglogo"></span>
        </div>

        <img class="fl" src="../assets/images/insuranceImg1.png" alt="" />
        <article>
          <div class="publicTit pc-publicTit">
            <div class="font30 tit color444 fontBold">我们的医保服务</div>
            <span class="hLine bglogo"></span>
          </div>
          <p>
            同云诊所服务平台于2019年开始研发，旨在为诊所提供数字化一站式解决方案，让诊所在短时间内提升数字化管理水平，符合政府部门监管要求。
          </p>
          <p>
            为社区诊所快速解决药品进销存统计困难、患者看病流程复杂、医生与患者沟通难的问题。从而使诊所的经营流程便捷化、信息化、数字化，也能缓解患者看病难，看病贵等现象。
          </p>
          <p>
            同云于2021年9月份实现新医保的对接，当前已在湖南、江西、福建、湖北、广西、重庆、河南、安徽等多地上线，同时也正在积极对接其它省份的新医保。
          </p>
        </article>
      </div>
    </div>
    <div class="item2 paddingTop4 paddingBot4" style="background: #fafafa">
      <div class="autowidth">
        <div class="publicTit">
          <div class="font30 tit color444 fontBold">我们的医保服务</div>
          <span class="hLine bglogo"></span>
        </div>
        <el-row :gutter="100">
          <template v-for="(item, index) in serverList">
            <el-col :key="index" :span="12">
              <el-card>
                <img :src="item.imgSrc" alt="" />
                <span class="fontBold">{{ item.font }}</span>
              </el-card>
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
    <!-- <div class="item3 paddingTop4 paddingBot4">
      <div class="autowidth">
        <div class="publicTit">
          <div class="font30 tit color444 fontBold">医保上线地区地图</div>
          <span class="hLine bglogo"></span>
        </div>
        <div class="mapArea" id="insuranceMap"></div>
      </div>
    </div> -->
    <Footer v-if="isSource !== 'applet'" />
  </div>
</template>

<script>
// import * as echarts from 'echarts'
import chinaMap from '../assets/json/china.json'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Insurance',
  components: {
    Footer
  },
  data() {
    return {
      serverList: [
        {
          imgSrc: require('../assets/images/insuranceImg2.png'),
          font: '多省对接、医保接诊'
        },
        {
          imgSrc: require('../assets/images/insuranceImg3.png'),
          font: '三目贯标、医保售药'
        },
        {
          imgSrc: require('../assets/images/insuranceImg4.png'),
          font: '自动匹配、医保对账'
        },
        {
          imgSrc: require('../assets/images/insuranceImg5.png'),
          font: '多形式刷卡，对账流程顺畅'
        },
        {
          imgSrc: require('../assets/images/insuranceImg5.png'),
          font: '完善的服务体系，强大的售后服务团队'
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.init()
    })
  },
  computed: {
    isSource() {
      return this.$route.query && this.$route.query.source
    }
  },
  methods: {
    // init() {
    //   echarts.registerMap('china', chinaMap)
    //   let myChart = echarts.init(document.getElementById('insuranceMap'))
    //   myChart.setOption({
    //     visualMap: {
    //       left: '10%',
    //       bottom: '30%',
    //       orient: 'horizontal',
    //       min: 0,
    //       max: 10000,
    //       show: false,
    //       textStyle: {
    //         color: '#fff'
    //       },
    //       inRange: {
    //         color: ['#cdebfe', '#0f9af1']
    //       }
    //     },
    //     series: [
    //       {
    //         type: 'map',
    //         map: 'china',
    //         itemStyle: {
    //           // areaColor: '#C3E4DC',
    //           areaColor: '#cdebfe',
    //           borderColor: '#99A9A5'
    //         },
    //         emphasis: {
    //           label: {
    //             show: true
    //           },
    //           itemStyle: {
    //             areaColor: '',
    //             shadowBlur: 20,
    //             shadowOffsetX: 0,
    //             shadowColor: 'rgba(0, 0, 0, 0.5)'
    //           }
    //         },
    //         zoom: 1.2,
    //         data: [
    //           {
    //             name: '湖南省',
    //             value: 9052
    //           },
    //           {
    //             name: '湖北省',
    //             value: 6423
    //           },
    //           {
    //             name: '江西省',
    //             value: 6238
    //           },
    //           {
    //             name: '广西壮族自治区',
    //             value: 5183
    //           },
    //           {
    //             name: '安徽省',
    //             value: 2437
    //           },
    //           {
    //             name: '广东省',
    //             value: 1785
    //           },
    //           {
    //             name: '河南省',
    //             value: 5019
    //           },
    //           {
    //             name: '福建省',
    //             value: 950
    //           },
    //           {
    //             name: '贵州省',
    //             value: 287
    //           },
    //           {
    //             name: '重庆市',
    //             value: 1492
    //           },
    //           {
    //             name: '云南省',
    //             value: 230
    //           }
    //         ]
    //       }
    //     ]
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.publicTit {
  text-align: left;
}
.item1 {
  .mobile-publicTit {
    display: none;
  }
  text-align: left;
  .autowidth {
    height: auto;
    overflow: hidden;
  }
  img {
    width: 35%;
    margin-right: 5%;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  article {
    margin-left: 10px;
    p {
      text-indent: 2em;
      line-height: 30px;
    }
  }
}
.item2 {
  /deep/ {
    .el-row {
      margin-top: 20px;
    }
    .el-col {
      margin-bottom: 70px;
    }
    .el-card__body {
      text-align: left;
      img {
        width: 60px;
        margin-right: 32px;
      }
    }
  }
}
.item3 {
  .publicTit {
    text-align: center;
  }
  .mapArea {
    width: 100%;
    height: 500px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 640px) {
  .item1 {
    .mobile-publicTit {
      display: block;
      text-align: center;
      margin-bottom: 1%;
    }
    .pc-publicTit {
      display: none;
    }

    img {
      width: 70%;
      display: block;
      float: inherit;
      margin: 0 auto 4%;
    }
    article p {
      line-height: 1.5;
    }
  }
  .item2 {
    padding-bottom: 25%;
    .publicTit {
      text-align: center;
    }
    .el-row {
      margin: 0 !important;
    }
    .el-col-12 {
      width: 100%;
      margin-bottom: 1%;
      padding: 0 !important;
    }
    .el-card__body img {
      width: 40px;
      margin-right: 5%;
    }
  }
  /deep/ .aboutBox {
    display: none;
  }
  .item3 .mapArea {
    width: 100%;
    height: 400px;
  }
}
</style>
